import "./ErrorPage.css";

export function ErrorPage() {
  return (
    <div className="error-page">
      <div className="error-content">
        <div className="error-text">
          <h1>Sorry something went wrong</h1>
          <p>We are already working on fixing it!</p>
          <p className="reload-text">
            <span className="arrow">&rarr;</span>{" "}
            <strong>Try reloading the app</strong>
          </p>
        </div>
        <div className="error-image">
          <img src="/assets/error-banner.png" alt="Error illustration" />
        </div>
      </div>
    </div>
  );
}
